<!--
 * @Author: dongjia
 * @Date: 2022-04-22 16:25:42
 * @LastEditTime: 2022-04-22 18:53:49
 * @LastEditors: aleaner
 * @Description: 举报记录弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\common\components\ReportRecodeDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="`举报记录（共 ${total} 条）`" :visible.sync="openDialog" width="530px" :show-close="true"
    class="dialog-vertical">
    <el-scrollbar class="scroll" v-loading="loading" v-loadmore="LoadMore">
      <div class="list-item" v-for="(item, index) in report_list" :key="index">
        <div class="item-label">举报内容：</div>
        <div class="item-right">
          <div class="item-content" v-if="item.data_res.content">{{item.data_res.content}}</div>
          <!-- 媒体资源内容 -->
          <div class="item-medias" :style="`margin-top: ${item.data_res.content?'8':'0'}px`"
            v-if="showMediaContent(item)">
            <!-- 图片 -->
            <div class="images" v-if="item.data_res.images || (item.data_res.medias && item.data_res.medias[0].type === 'image')">
              <div class="item-img-box cursor-pointer" v-for="(item, index) in imgList(item)"
                @click="$previewImage([item.url])" :key="index">
                <el-image class="item-img" :src="fixImageUrl(item.url)" fit="cover">
                </el-image>
              </div>
            </div>
            <!-- 视频 -->
            <div class="video cursor-pointer"
              v-if="(item.data_res.video && item.data_res.video.length > 0) || (item.data_res.medias && item.data_res.medias[0].type === 'video')"
              @click="handlePlay(videoList(item)[0])">
              <el-image class="video-cover" :src="fixImageUrl(videoList(item)[0].thumbnail)" fit="cover">
              </el-image>
              <img class="play-icon" src="@/base/assets/images/media/video-play@2x.png" />
            </div>
          </div>
          <!-- 内容信息 -->
          <div class="item-msg">
            <el-row>
              <el-col :span="12">
                <div class="msg-item"><span class="msg-label">举报人：</span><span
                    class="msg-content">{{item.user_nickname}}</span></div>
              </el-col>
              <el-col :span="12">
                <div class="msg-item"><span class="msg-label">状态：</span><span class="msg-content"><span
                      class="status-dot" :style="{
                  backgroundColor: item.status?'#67C23A':'#F56C6C'
                }"></span>{{item.status_text}}</span></div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="msg-item"><span class="msg-label">举报时间：</span><span
                    class="msg-content">{{item.create_time}}</span></div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="msg-item"><span class="msg-label">举报原因：</span><span
                    class="msg-content">{{item.reason}}</span></div>
              </el-col>
            </el-row>
          </div>
          <!-- 反馈处理结果 -->
          <el-button class="item-btn" type="primary" @click="feedbackResult(item)"
            :disabled="item.status === 1">
            反馈处理结果</el-button>
        </div>
      </div>
    </el-scrollbar>
    <!-- 反馈处理结果弹窗 -->
    <ReportHandleDialog v-model="showReportHandleDialog" :id="current_id" :updateList="refreshList" />
    <!-- 视频弹窗 -->
    <VideoDialog />
  </el-dialog>
</template>

<script>
import debounce from "lodash/debounce";
import { reportRecodeList } from "../api/report-recode.js";
import ReportHandleDialog from "@/modules/report-admin/components/ReportHandleDialog.vue";
import VideoDialog from "@/base/components/Preview/VideoViewer";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  components: { ReportHandleDialog, VideoDialog },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    requestData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      no_more: false,
      request_data: null,
      report_list: [],
      showReportHandleDialog: false,
      current_id: "",
      total: 0
    };
  },
  methods: {
    // 获取举报记录列表数据
    getReportRecordList() {
      if (this.no_more) return;
      this.loading = true;
      reportRecodeList(this.request_data)
        .then((res) => {
          const { data } = res;
          this.total = data.total;
          this.report_list =
            this.request_data.page === 1
              ? data.data
              : [...this.report_list, ...data.data];
          if (data.data.length >= this.request_data.page_size) {
            this.request_data.page++;
          } else {
            this.no_more = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 加载更多
    LoadMore: debounce(
      function () {
        this.getReportRecordList();
      },
      2000,
      { leading: true }
    ),
    // 刷新列表数据
    refreshList() {
      this.request_data.page = 1;
      this.no_more = false;
      this.getReportRecordList();
    },
    // 反馈处理结果
    feedbackResult(item) {
      this.current_id = item.id;
      this.showReportHandleDialog = true;
    },
    // 是否显示资源内容
    showMediaContent(item) {
      return (
        (item.data_res.images && item.data_res.images.length) ||
        (item.data_res.video && item.data_res.video.length) ||
        (item.data_res.medias && item.data_res.medias.length)
      );
    },
    // 图片资源列表
    imgList(item) {
      return item.data_res.images ? item.data_res.images : item.data_res.medias;
    },
    // 视频资源列表
    videoList(item) {
      return item.data_res.video ? item.data_res.video : item.data_res.medias;
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit("media/setIsOpenMediaVideoDialog", true);
      this.$store.commit("media/setDialogDetail", data);
    },
  },
  created() {},
  watch: {
    openDialog(val) {
      if (val) {
        this.request_data = JSON.parse(JSON.stringify(this.requestData));
        this.no_more = false;
        this.getReportRecordList();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll {
  height: 600px;
  .list-item + .list-item {
    margin-top: 32px;
  }
  .list-item {
    display: flex;
    width: 100%;
    .item-label {
      flex-shrink: 0;
      font-size: 16px;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 24px;
    }
    .item-right {
      flex: 1;
      margin-left: 4px;
      .item-content {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 24px;
        white-space: pre-line;
      }
      .item-medias {
        .images {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          row-gap: 8px;
          column-gap: 8px;
          .item-img-box {
            @include fixed-ratio-box(100%);
            .item-img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }

        .video {
          width: 319px;
          height: 180px;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          .video-cover {
            width: 100%;
            height: 100%;
          }
          .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 42px;
            height: 42px;
          }
        }
      }
      .item-msg {
        margin-top: 24px;
        .el-row + .el-row {
          margin-top: 16px;
        }
        .msg-item {
          display: flex;
          align-items: center;
          .msg-label {
            flex-shrink: 0;
            font-size: 14px;
            color: #808080;
            line-height: 21px;
          }
          .msg-content {
            .status-dot {
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              margin-right: 6px;
              line-height: 21px;
            }
            margin-left: 8px;
            font-size: 14px;
            color: #1a1a1a;
            line-height: 21px;
          }
        }
      }
      .item-btn {
        margin-top: 24px;
      }
    }
  }
}
</style>
