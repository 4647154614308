/*
 * @Author: dongjia
 * @Date: 2021-08-17 11:02:58
 * @LastEditTime: 2022-03-30 15:25:55
 * @LastEditors: aleaner
 * @Description: 黑名单列表接口
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\api\black-member.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取黑名单列表
export const blackList = data => {
  return api({
    url: "/admin/circle/black/index",
    method: "post",
    data
  });
};

// 永久拉黑/取消永久拉黑
export const foreverBlack = data => {
  return api({
    url: "/admin/circle/black/foreverBlack",
    method: "post",
    data
  });
};

// 移出黑名单
export const cancelBlack = data => {
  return api({
    url: "/admin/circle/black/cancelBlack",
    method: "post",
    data
  });
};

// 拉黑
export const setBlack = (data, type) => {
  return api({
    url: `/admin/circle/${type}/changeBlack`,
    method: "post",
    data
  });
};