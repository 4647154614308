<!--
 * @Author: dongjia
 * @Date: 2021-08-23 14:48:42
 * @LastEditTime: 2021-08-23 15:33:34
 * @LastEditors: aleaner
 * @Description: 评论回复弹窗
 * @FilePath: \saas-admin-vue\src\modules\community-admin\components\postAdmin\ReplyDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="commentType === 'add' ? '添加' : '编辑'"
    :visible.sync="openDialog"
    width="700px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('reply')"
    @open="onOpen"
  >
    <el-form
      v-if="myData"
      size="medium"
      :model="ContentData"
      :rules="rules"
      ref="reply"
      label-width="100px"
    >
      <el-form-item label="问题：" prop="content">
        <el-input
          type="textarea"
          :rows="6"
          v-model="ContentData.content"
        ></el-input>
      </el-form-item>
      <el-form-item label="问题描述：" prop="description">
        <el-input
          type="textarea"
          :rows="6"
          v-model="ContentData.description"
        ></el-input>
      </el-form-item>
      <el-form-item label="问题分类：" prop="category_id">
        <el-select v-model="ContentData.category_id" placeholder="请选择">
          <el-option
            v-for="item in categoryOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片：" prop="images">
        <media-wall
          v-model="ContentData.images"
          :width="150"
          :height="96"
          :limit="9 - ContentData.images.length"
          addText="上传图片"
          :UploadType="['image']"
        ></media-wall>
      </el-form-item>
      <el-form-item label="视频：" prop="video">
        <single-media-wall
          v-model="ContentData.video[0]"
          :width="194"
          :height="135"
          :borderRadius="4"
          addText="添加视频"
          UploadType="video"
        ></single-media-wall>
      </el-form-item>
      <el-form-item label="引用文章：" prop="article_id">
        <el-button
          @click="openHandleList = true"
          :style="{
            marginRight: '12px',
          }"
          >选择文章</el-button
        >
        <template v-if="ContentData.article_name">
          <span
            :style="{
              marginRight: '12px',
            }"
          >
            已选择： {{ ContentData.article_name }}
          </span>
          <el-button @click="clearArticle">清空</el-button>
        </template>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('reply')" :loading="saveLoading"
        >保存</el-button
      >
    </span>

    <!-- 选择文章弹窗 -->
    <SelectArticleDialog
      v-model="openHandleList"
      @change="changeArticleSelect"
      :selected="[{ id: ContentData.article_id }]"
    ></SelectArticleDialog>
  </el-dialog>
</template>

<script>
import ImageItem from '@/modules/common/components/ImageItem.vue'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import SelectArticleDialog from './SelectArticleDialog.vue'
import { categoryList } from '../../api/consult-content-list/category.js'
import { newContent } from '../../api/consult-content-list/list.js'

export default {
  components: {
    ImageItem,
    SingleMediaWall,
    SelectArticleDialog,
    MediaWall,
  },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    commentType: {
      type: String,
      default: 'add',
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      optionsLoading: false,
      rules: {
        content: [{ required: true, message: '请输入问题', trigger: 'blur' }],
        category_id: [
          { required: true, message: '请选择问题分类', trigger: 'blur' },
        ],
      },
      // 分类配置
      categoryOption: [],
      // 选择文章
      openHandleList: false,
      myData: '',
    }
  },
  methods: {
    clearArticle() {
      this.ContentData.article_name = ''
      this.ContentData.article_id = ''
    },
    // 选择文章回调
    changeArticleSelect(data) {
      console.log(data)
      this.ContentData.article_id = data.id
      this.ContentData.article_name = data.name
    },
    // 弹窗打开
    onOpen() {
      console.log(this.ContentData)
      this.myData = JSON.parse(JSON.stringify(this.Data))
      categoryList({ tab: 'normal' }).then((res) => {
        console.log(res)
        this.categoryOption = res.data.list
      })
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          console.log(this.ContentData)
          newContent({
            ...this.ContentData,
            video: this.ContentData.video[0].url ? this.ContentData.video : [],
          }).then((res) => {
            this.openDialog = false
            this.saveLoading = false
            this.$refs[formName].resetFields()
            this.$message.success(res.msg)
            this.updateList()
            console.log(res)
          })
          // if (this.commentType === 'comment') {
          //   commentPost(this.ContentData)
          //     .then((res) => {
          //       this.$message.success(res.msg)
          //       this.updateList()
          //       this.$refs[formName].resetFields()
          //       this.openDialog = false
          //       this.saveLoading = false
          //     })
          //     .catch((err) => {
          //       this.saveLoading = false
          //     })
          // } else {
          //   replyComment(this.ContentData)
          //     .then((res) => {
          //       this.$message.success(res.msg)
          //       this.updateList()
          //       this.$refs[formName].resetFields()
          //       this.openDialog = false
          //       this.saveLoading = false
          //     })
          //     .catch((err) => {
          //       this.saveLoading = false
          //     })
          // }
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      this.ContentData.article_id = ''
      this.ContentData.article_name = ''
      this.$refs[formName].resetFields()
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },

    ContentData: {
      get() {
        // if (!data.video.length) {
        //   data.video = [{}]
        // }
        // return data
        return this.myData
      },
      set(val) {
        console.log(val)
        if (this.$props.commentType !== 'add') {
          this.$emit('update: updateData', val)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>
