<!--
 * @Author: dongjia
 * @Date: 2022-03-15 10:52:26
 * @LastEditTime: 2022-03-21 10:20:25
 * @LastEditors: aleaner
 * @Description: 设置置顶弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\components\postAdmin\SetTopDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="设置置顶" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('setTop')">
    <el-form size="medium" :model="setTopData" :rules="rules" ref="setTop" label-width="80px">
      <el-form-item label="置顶时间：" prop="top_time">
        <el-date-picker v-model="setTopData.top_time" type="daterange" value-format="timestamp"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>置顶说明：按置顶时间显示前两条数据</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('setTop')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setTop } from "../../api/consult-content-list/list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    post_id: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      setTopData: {
        top_time: "",
      },
      rules: {
        top_time: [
          { required: true, message: "请选择置顶时间", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const result = Object.assign(
            {},
            {
              id: this.post_id,
              top_start_time: this.setTopData.top_time[0] / 1000,
              top_end_time: this.setTopData.top_time[1] / 1000,
            }
          );

          this.saveLoading = true;
          setTop(result)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>