// 举报记录接口
import api from "@/base/utils/request";

//获取选择会员列表
export const reportRecodeList = data => {
  return api({
    url: "/admin/complaint/index/index",
    method: "post",
    data
  });
};