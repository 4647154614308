/*
 * @Author: dongjia
 * @Date: 2022-04-12 11:35:44
 * @LastEditTime: 2022-04-12 18:16:53
 * @LastEditors: aleaner
 * @Description: 举报列表接口
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\report-admin\api\list.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//获取列表数据
export const reportList = data => {
  return api({
    url: "/admin/complaint/index/index",
    method: "post",
    data
  })
}

// 举报反馈处理
export const handleReport = data => {
  return api({
    url: "/admin/complaint/index/handle",
    method: "post",
    data
  })
}