<!--
 * @Author: dongjia
 * @Date: 2022-04-12 18:09:22
 * @LastEditTime: 2022-04-22 18:07:49
 * @LastEditors: aleaner
 * @Description: 处理举报内容反馈
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\report-admin\components\ReportHandleDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="反馈处理结果" :visible.sync="openDialog" width="500px" :show-close="true"
    class="dialog-vertical" append-to-body @close="cancel('noCheck')">
    <el-form size="medium" :model="noCheckData" :rules="rules" ref="noCheck" label-width="120px">
      <el-form-item label="处理结果（反馈给举报人）：" prop="handle_result">
        <el-input type="textarea" :rows="3" maxlength="20" show-word-limit v-model="noCheckData.handle_result"
          placeholder="请输入"></el-input>
        <div class="form-tip" style="margin-top: 5px">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC;line-height: 19px;"></span>
          <span>将通过短信、订阅消息、系统消息发送处理结果</span>
        </div>
      </el-form-item>
      <el-form-item label="违规原因（反馈给被举报人，如内容不违规时无需填写）：" prop="handle_reason">
        <el-input type="textarea" :rows="3" maxlength="20" show-word-limit v-model="noCheckData.handle_reason"
          placeholder="请输入"></el-input>
        <div class="form-tip" style="margin-top: 5px">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC;line-height: 19px;"></span>
          <span>将通过短信、订阅消息、系统消息发送处理结果</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('noCheck')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { handleReport } from "../api/list.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: "",
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      optionsLoading: false,
      noCheckData: {
        handle_result: "",
        handle_reason: "",
      },
      rules: {
        handle_result: [
          { required: true, message: "请输入处理结果", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let noCheckData = { ...this.noCheckData, id: this.id };
          handleReport(noCheckData)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-form-item__label {
    line-height: 1.2;
    white-space: normal;
  }
}

.form-tip {
  display: flex;
  font-size: 12px;
  color: #606266;
  line-height: 18px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>