/*
 * @Author: dongjia
 * @Date: 2021-08-16 18:20:10
 * @LastEditTime: 2021-08-23 15:19:29
 * @LastEditors: aleaner
 * @Description: 新增帖子页面
 * @FilePath: \saas-admin-vue\src\modules\community-admin\api\post-admin\detail.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取帖子详情
export const postDetail = data => {
  return api({
    url: "/admin/consult/content/detail",
    method: "post",
    data
  });
};

// 保存帖子详情信息
export const savePost = data => {
  return api({
    url: "/admin/consult/post/save",
    method: "post",
    data
  });
};

// 获取帖子详情评论列表
export const commentList = data => {
  return api({
    url: "/admin/consult/content/detailComment",
    method: "post",
    data
  });
};

// 获取帖子详情更多评论
export const moreComment = data => {
  return api({
    url: "/admin/consult/content/moreComment",
    method: "post",
    data
  });
};

// 评论帖子
export const commentPost = data => {
  return api({
    url: "/admin/consult/content/commentPost",
    method: "post",
    data
  });
};

// 回复评论
export const replyComment = data => {
  return api({
    url: "/admin/consult/content/comment",
    method: "post",
    data
  });
};

// 删除评论
export const delComment = data => {
  return api({
    url: "/admin/consult/content/delComment",
    method: "post",
    data
  });
};