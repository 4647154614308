import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

// 分类列表数据
export const categoryList = data => {
  return api({
    url: '/admin/consult/ContentCategory/index',
    method: 'post',
    data
  });
};
// 保存分类排序
export const saveCategorySort = data => {
  return api({
    url: '/admin/consult/ContentCategory/saveSort',
    method: 'post',
    data
  });
};

// 更新分类名称
export const saveCategory = data => {
  return api({
    url: '/admin/consult/ContentCategory/save',
    method: 'post',
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/consult/ContentCategory/saveSort",
    method: "post",
    data
  });
};
// 转移
export const moveCategory = data => {
  return api({
    url: "/admin/consult/ContentCategory/move",
    method: "post",
    data
  });
};

// //更新推荐
// export const updateRecommend = data => {
//   return api({
//     url: '/admin/wx_open/wx_live/updateIsRecommend',
//     method: 'post',
//     data
//   });
// };


// // 更新显示
// export const updateShow = data => {
//   return api({
//     url: "/admin/cyc_activity/photo/updateShow",
//     method: "post",
//     data
//   });
// };

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/consult/ContentCategory/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/consult/ContentCategory/delete",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/consult/ContentCategory/putBack",
    method: "post",
    data
  });
};

// // 活动相册分类导出
// export const documentActivityPhotoCategoryList = data => {
//   return fetchFile({
//     url: "/admin/cyc_activity/photo/export",
//     method: "post",
//     data,
//     download: true
//   });
// };